import "./Home.scss";

import React, { useEffect, useState } from "react";

// react imports
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import HomePage from "./home_page";
import WhenWherePage from "./when_where_page";
import WhatIsPage from "./what_is_page";
import AboutPage from "./about_page";
import PrizePage from "./Prizes.js";
import SpeakerPage from "./speaker_page";
import FAQPage from "./faq_page";
// components and assets
import Section from "../../Components/Section";
import ScheduleSection from "../../Components/ScheduleSection";
import TrackCard from "../../Components/TrackCard";
import SpeakerCard from "../../Components/SpeakerCard";
import Faq from "../../Components/FAQ";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import SponsorFrame from "../../Assets/Images/Branding/Sponsor_Frame.png";
import CommunityFrame from "../../Assets/Images/Branding/Community_Frame.png";

import dsgtlogotext from "../../Assets/Images/Branding/new-nobg.png";
import sxlogotext from "../../Assets/Images/Branding/sx-logo-2.png";
// import mlhbadge from "../../Assets/Images/Other/mlh-trust-badge-2025-black-ver.svg";
import mlhbadge from "../../Assets/Images/Branding/mlh-trust-badge-2025-white.png";

import prizetop3 from "../../Assets/Images/Prizes/prize-luggage.png";
import prizeevent from "../../Assets/Images/Prizes/prize-event.png";

import woodBanner from "../../Assets/Images/Branding/Wood_Header.png";

import assurant from "../../Assets/White_Logos/assurant.png";
import amazon from "../../Assets/White_Logos/Amazon.png";
import carelon from "../../Assets/White_Logos/carelon2.png";
import createx from "../../Assets/White_Logos/CREATE-X.png";
import deshawco from "../../Assets/White_Logos/DE_Shaw.png";
import elastic from "../../Assets/White_Logos/elastic.png";
import googlecloud from "../../Assets/White_Logos/Google Cloud.png";
import google from "../../Assets/White_Logos/Google.png";
import inteldev from "../../Assets/White_Logos/Intel Developer Cloud.png";
import intel from "../../Assets/White_Logos/Intel.png";
import johndeere from "../../Assets/White_Logos/John Deere.png";
import telora from "../../Assets/White_Logos/telora.png";
import traversaal from "../../Assets/White_Logos/traversaal.png";
import wolfram from "../../Assets/White_Logos/Wolfram.png";
import nsa from "../../Assets/Images/Sponsors/nsa.png";
import coc from "../../Assets/Images/CommunityPartners/coc.png";
import datadays from "../../Assets/Images/CommunityPartners/Data Days.png";
import isye from "../../Assets/Images/CommunityPartners/isye.png";
import scheller2 from "../../Assets/Images/CommunityPartners/scheller2.png";
import onetrust from "../../Assets/White_Logos/OneTrust.png";
import microsoft from "../../Assets/White_Logos/Microsoft.png";
import kinetic from "../../Assets/White_Logos/Kinetic Atlanta.png";
import headstarter from "../../Assets/White_Logos/HeadStarter.png";
import archetype from "../../Assets/White_Logos/Archetype AI.png";
import ideas from "../../Assets/Images/CommunityPartners/Ideas Logo.png";
import soonami from "../../Assets/Images/Sponsors/soonami2.png";
import lenovo from "../../Assets/Images/Sponsors/lenovo.png";
import brightdata from "../../Assets/White_Logos/brightdata.png";
import connectwise from "../../Assets/White_Logos/connectwise.png";
import logo from "../../Assets/Images/Branding/Hacklytics 2025 Logo.png";
import Clouds from "../../Components/Clouds";

//imports
import "./Home.css";

import "react-lazy-load-image-component/src/effects/blur.css";

// import ScrollSection from "../../Components/ScrollSection";

// import map from "../../Assets/Images/Other/map.png";

// import andrew from "../../Assets/Images/Speakers/andrew.jpg";
// import chris from "../../Assets/Images/Speakers/chris.png";
// import keith from "../../Assets/Images/Speakers/keith.png";
// import rahul from "../../Assets/Images/Speakers/rahul.png";

import nick from "../../Assets/Images/Speakers/nick.jpg";
// import ankush from "../../Assets/Images/Speakers/ankush.jfif";

// prizes
import iPad from "../../Assets/Images/Prizes/iPad.jpg";
import metaquest from "../../Assets/Images/Prizes/metaquest.jpg";
import nintendoswitch from "../../Assets/Images/Prizes/switch.webp";

// current sponsor images
import millennium from "../../Assets/Images/Sponsors/millennium.svg";
import elevance from "../../Assets/Images/Sponsors/elevance.png";
import geico from "../../Assets/Images/Sponsors/geico.png";

const Home = (params) => {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  const handleScroll = () => {
    const bannerHeight = document.querySelector(
      ".travel-reimbursement-banner"
    ).clientHeight;
    const scrollTop = window.scrollY;

    if (scrollTop > bannerHeight / 2) {
      setIsNavbarFixed(true);
    } else {
      setIsNavbarFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="home-page">
      <div className="travel-reimbursement-banner">
        <p className="banner-text">
          Travel Reimbursement Applications close 12/17. General Applications
          close 1/20.
        </p>
      </div>
      <Navbar isFixed={isNavbarFixed} />

      <div className="mlh-section">
        <div className="mlh-wrapper">
          <a className="mlh-badge" href="https://mlh.io/">
            <LazyLoadImage effect="blur" src={mlhbadge} alt="mlh badge" />
          </a>
        </div>
      </div>
      <HomePage />
      <WhenWherePage />
      <AboutPage />
      <WhatIsPage />
      {/* { <Section id="schedule">
        <div className="schedule-flex">
          <h1 className="major">Schedule</h1>
          // { <EventScheduleSection /> }
        </div>
      </Section> } */}
      <Section id="tracks">
        <div className="tracks-flex">
          <h1 className="major">
            Tracks
            <br />
            <span className="clicktoflip">click cards to flip</span>
          </h1>
          <TrackCard identifier="a1" track="Sports">
            In this track, you will be working on sports analytics challenges
            that will take your data science and machine learning skills to the
            next level.
          </TrackCard>
          <TrackCard identifier="b2" track="Healthcare">
            In this track, you will be analyzing some of these datasets to help
            solve crucial, real-world problems in public health or the
            biomedical sciences.
          </TrackCard>
          {/* <TrackCard identifier="c3" track="Sustainability">
            In this track, you will be analyzing data with one of several goals,
            including resource/waste management, adapting to climate change, and
            more.
          </TrackCard> */}
          <TrackCard identifier="d4" track="Finance">
            In this track, you will be working on data science challenges that
            touch almost every area of finance and banking, such as trading
            decisions, market forecasting, customer sentiment, and more.
          </TrackCard>
          <TrackCard identifier="e5" track="Cybersecurity">
            In this track, you will be tackling data-driven challenges in
            cybersecurity. You'll analyze and develop solutions to protect
            sensitive information, detect threats, and ensure the integrity of
            digital systems.
          </TrackCard>
          <TrackCard identifier="f6" track="Generative AI">
            Explore the world of Generative AI in this Microsoft-sponsored
            track. Participants will work on projects including creating novel
            artworks and solving real-world challenges using innovative AI
            solutions.
          </TrackCard>
          {/* <Faq identifier="e5" question="Healthcare"></Faq>
                    <Faq identifier="f6" question="Healthcare"></Faq> */}
        </div>
        <a className="continue-reading" href="#prizes">
          <span className="text">Prizes</span>
          <span className="arrow">↓</span>
        </a>
      </Section>
      <PrizePage />
      {/* <Section id="whatis2">
        <div className="whatis-flex">
          <h2 className="major">What is DSGT?</h2>
          <p className="mini">
            Data Science @ Georgia Tech is the largest data science organization
            at Georgia Tech with over 900 members. Our motto, “data science with
            a focus on community,” is manifested through our projects, courses,
            and events. Learn more about us or how you can be involved{" "}
            <a href="https://datasciencegt.org">here</a>.
          </p>
        </div>
      </Section> */}

      <FAQPage />
      <SpeakerPage />

      <Section id="sponsors">
        <div className="pastsponsors-flex">
          <div className="wood-sponsor-banner">
            <img
              src={SponsorFrame}
              alt="About"
              className="banner-sponsor-img "
            />
          </div>
          <div className="sponsors-flex">
            {/* <a className="sponsor-largest" href="https://www.intel.com/content/www/us/en/developer/tools/devcloud/overview.html" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={inteldev}
                                alt="Intel Developer Cloud"
                            />
                        </a> */}
            <a
              className="sponsor-large"
              href="https://www.intel.com/content/www/us/en/homepage.html"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={intel}
                alt="Intel"
              />
            </a>
            <a className="sponsor" href="https://www.nsa.gov/" target="_blank">
              <LazyLoadImage
                effect="blur"
                className="sponsor"
                src={nsa}
                alt="National Security Agency"
              />
            </a>
            <a
              className="sponsor-large"
              href="https://www.assurant.com/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor-larger invert-colors"
                src={assurant}
                alt="assurant"
              />
            </a>
            <a
              className="sponsor-larger"
              href="https://www.carelon.com/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={carelon}
                alt="Carelon"
              />
            </a>
            <a
              className="sponsor-larger invert-colors"
              href="https://careers.microsoft.com/v2/global/en/students"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor"
                src={microsoft}
                alt="microsoft"
              />
            </a>
            <a
              className="sponsor-large"
              href="https://www.deshaw.com/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={deshawco}
                alt="deshawco"
              />
            </a>
            <a
              className="sponsor-small"
              href="https://www.deere.com/en/index.html"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={johndeere}
                alt="john deere"
              />
            </a>
            <a
              className="sponsor-larger"
              href="https://www.connectwise.com/platform/on-demand-demos?mcid=PPC6350&utm_medium=PPC&utm_source=Google&utm_campaign=PPC6350&utm_content=demo&loc=NA&cq_cmp=15836773817&cq_plac=&cq_net=g&utm_term=connectwise&_bt=689022999050&_bk=connectwise&_bm=e&_bn=g&_bg=135619274361&cq_cmp=15836773817&cq_plac=&cq_net=g&gad_source=1&gclid=CjwKCAiA8YyuBhBSEiwA5R3-E-1dzfx_PYPJVcn8HpV4cbrDdDcFbvse13qD21YKXF2TkLMrsW8HkhoCZv0QAvD_BwE&gclsrc=aw.ds"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={connectwise}
                alt="connectwise"
              />
            </a>
            <a
              className="sponsor"
              href="https://www.elastic.co/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={elastic}
                alt="elastic"
              />
            </a>
            <a
              className="sponsor-smaller"
              href="https://create-x.gatech.edu/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={createx}
                alt="createx"
              />
            </a>
            <a className="sponsor" href="https://soonami.io/" target="_blank">
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={soonami}
                alt="soonami"
              />
            </a>
            <a
              className="sponsor-smaller"
              href="https://www.aws.amazon.com/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={amazon}
                alt="Amazon"
              />
            </a>
            <a
              className="sponsor"
              href="https://www.archetypeai.io/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={archetype}
                alt="archetype ai"
              />
            </a>
            <a
              className="sponsor"
              href="https://traversaal.ai/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={traversaal}
                alt="traversaal"
              />
            </a>
            <a
              className="sponsor"
              href="https://theheadstarter.com/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={headstarter}
                alt="headstarter"
              />
            </a>
            <a
              className="sponsor-smaller"
              href="https://telora.com/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={telora}
                alt="telora"
              />
            </a>
            <a
              className="sponsor-smaller"
              href="https://cloud.google.com/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={googlecloud}
                alt="googlecloud"
              />
            </a>
            <a
              className="sponsor-smaller"
              href="https://about.google/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={google}
                alt="google"
              />
            </a>
            <a
              className="sponsor"
              href="https://www.wolfram.com/language/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={wolfram}
                alt="wolfram"
              />
            </a>
            <a
              className="sponsor"
              href="https://brightdata.com/?kw=bright%20data&cpn=12540480407&utm_matchtype=e&utm_matchtype=e&cq_src=google_ads&cq_cmp=12540480407&cq_term=bright%20data&cq_plac=&cq_net=g&cq_plt=gp&utm_term=bright%20data&utm_campaign=brand_brightdata-all_geos-search_brand-kw_en-desktop&utm_source=adwords&utm_medium=ppc&utm_content=bright-data-proxy&hsa_acc=1393175403&hsa_cam=12540480407&hsa_grp=120400519099&hsa_ad=605257297612&hsa_src=g&hsa_tgt=kwd-1198517744079&hsa_kw=bright%20data&hsa_mt=e&hsa_net=adwords&hsa_ver=3&gad_source=1&gclid=Cj0KCQiAwvKtBhDrARIsAJj-kTisYAJM4K1zSLmVwCxMO8nPET006wVJvjVnrIeWpSTKjOsphiPTzDwaAjkSEALw_wcB"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={brightdata}
                alt="brightdata"
              />
            </a>
            {/* <a className="sponsor-small" href="https://www.kineticatlanta.com/" target="_blank">
                            <LazyLoadImage
                                effect="blur"
                                className="sponsor"
                                src={kinetic}
                                alt="kinetic atlanta"
                            />
                        </a> */}
          </div>
          <div className="wood-sponsor-banner">
            <img
              src={CommunityFrame}
              alt="About"
              className="banner-sponsor-img "
            />
          </div>
          <div className="sponsors-flex">
            <a
              className="sponsor-large"
              href="https://www.startup.exchange/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={sxlogotext}
                alt="Startup Exchange"
              />
            </a>
            <a
              className="sponsor-small"
              href="https://www.isye.gatech.edu/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={isye}
                alt="isye"
              />
            </a>
            <a
              className="sponsor-larger"
              href="https://www.scheller.gatech.edu/index.html"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={scheller2}
                alt="scheller"
              />
            </a>
            <a
              className="sponsor-small"
              href="https://www.cc.gatech.edu/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={coc}
                alt="coc"
              />
            </a>
            <a
              className="sponsor-small"
              href="https://datax.gatech.edu/"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor"
                src={datadays}
                alt="data days"
              />
            </a>
            <a
              className="sponsor-larger"
              href="https://research.gatech.edu/data"
              target="_blank"
            >
              <LazyLoadImage
                effect="blur"
                className="sponsor invert-colors"
                src={ideas}
                alt="ideas"
              />
            </a>
          </div>
        </div>
      </Section>
      <Footer />
    </div>
  );
};
export default Home;
